.table-container {
  background-color: white;
  color: #3a3f56 !important;
  padding: 20px;
  border-radius: 10px;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  display: block;
  width: auto;
  max-width: 90%;
  overflow-x: auto;
}

@media screen and (max-width: 768px) {
  .table-container {
    max-width: 95%;
  }
}

.table-container h1 {
  text-align: center;
  padding-bottom: 20px;
}

.table-container .table {
  width: 100%;
  margin-bottom: 10px;
  font-size: 1.2rem;
  margin-right: auto;
  margin-left: auto;
}

@media screen and (max-width: 768px) {
  .table-container .table {
    font-size: 1rem;
  }
}

.table-container .table th {
  border-bottom: 1px solid #e6edf5 !important;
  text-align: left;
  white-space: nowrap;
  padding-right: 20px;
}

.table-container .table td {
  border-bottom: 1px solid #e6edf5 !important;
  text-align: left;
  padding: 10px 0;
  padding-right: 20px;
}

.table-container .table .online {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 5px;
}

.table-container .table .online > div {
  display: inline;
  width: 16px;
  height: 16px;
  background-color: #0eed0e;
  border-radius: 8px;
}

.table-container .table .offline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 5px;
}

.table-container .table .offline > div {
  display: inline;
  width: 16px;
  height: 16px;
  background-color: #ff3700;
  border-radius: 8px;
}
