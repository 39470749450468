.newTable-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    flex-wrap: wrap;

    color: white;
    background-color: white;
    padding: 20px;
    border-radius: 10px;

    @media screen and (max-width: 475px) {
        padding: 10px;
        gap: 10px;
    }

    .ONLINE {
        background-color: #60BEBB;
    }

    .OFFLINE {
        background-color: #DB4444;
    }

    .newTable-item-container {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 5px;
        flex-direction: column;
        min-width: 360px;
        width: 420px;
        border-radius: 10px;
        padding: 10px 20px;

        height: 290px;

        @media screen and (max-width: 450px) {
            min-width: 320px;
            height: 230px;
        }

        & > div {
            margin: 10px 0;
            @media screen and (max-width: 450px) {
                margin: 5px 0;
            }
        }

        .newTable-domain {
            font-size: 28px;
            @media screen and (max-width: 450px) {
                font-size: 22px;
            }
        }

        .newTable-flex-container {
            display: flex;
            align-items: center;
            
            gap: 10px;
        }

        .newTable-users-cpu, .newTable-memory-ts {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            
            & > div {
                width: 50%;
            }

            .newTable-text {
                font-size: 12px;
                font-weight: normal;
            }

            .newTable-stats {
                font-weight: 600;
                font-size: 22px;
                @media screen and (max-width: 450px) {
                    font-size: 18px;
                }
            }
        }

        .newTable-status {
            p {
                font-size: 24px;
                font-weight: 500;
                margin: 0;
                @media screen and (max-width: 450px) {
                    font-size: 20px;
                }
            }
        }

        .ONLINE-status {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 15px;
            & > div {
                display: inline;
                width: 24px;
                height: 24px;
                background-color: #64FF83;
                border-radius: 12px;
            }
        }

        .OFFLINE-status {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 15px;
            & > div {
                display: inline;
                width: 24px;
                height: 24px;
                background-color: #FF7A7A;
                border-radius: 12px;
            }
        }
    }
}

.placeholderText-div {
    height: 50px;
    @media screen and (max-width: 450px) {
        height: 45.5px;
    }

    display: flex;
    justify-content: flex-start;
    align-items: center;

    font-size: 22px;
}