.container {
  width: 100%;
  max-width: 1600px;
  margin: auto;
  margin-top: 20px;
  padding: 10px;
}

@media screen and (max-width: 475px) {
  .container {
    margin-top: 10px;
    padding: 5px;
  }
}

.container h1, .container h2 {
  text-align: center;
}

.container h1 {
  font-size: 42px;
  font-weight: 600;
  padding: 0;
  margin: 0;
}

@media screen and (max-width: 450px) {
  .container h1 {
    font-size: 36px;
  }
}

.container h2 {
  font-size: 22px;
  font-weight: 400;
  padding: 0;
  margin: 0;
  margin-bottom: 20px;
}

@media screen and (max-width: 450px) {
  .container h2 {
    font-size: 18px;
  }
}

.container .all-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 10px;
}

@media screen and (max-width: 1150px) {
  .container .all-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

.container .all-container .TABLE-container {
  width: 60%;
}

@media screen and (max-width: 1150px) {
  .container .all-container .TABLE-container {
    width: 100%;
  }
}

@media screen and (min-width: 1150px) and (max-width: 1530px) {
  .container .all-container .TABLE-container {
    width: 40%;
  }
}

.container .all-container .TABLE-container .newTable-container {
  width: 100%;
  max-width: 900px;
}

@media screen and (min-width: 1150px) and (max-width: 1530px) {
  .container .all-container .TABLE-container .newTable-container {
    max-width: 450px;
  }
}

@media screen and (max-width: 1150px) {
  .container .all-container .TABLE-container .newTable-container {
    margin: auto;
  }
}

@media screen and (max-width: 930px) {
  .container .all-container .TABLE-container .newTable-container {
    max-width: 450px;
  }
}

.container .all-container .CHARTS-container {
  width: 29.8%;
}

@media screen and (min-width: 1150px) and (max-width: 1530px) {
  .container .all-container .CHARTS-container {
    width: 60%;
  }
}

@media screen and (max-width: 1150px) {
  .container .all-container .CHARTS-container {
    width: 100%;
  }
}

.container .video-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.container .video-container .newTable-container {
  width: 100%;
  max-width: 900px;
}

@media screen and (max-width: 930px) {
  .container .video-container .newTable-container {
    max-width: 450px;
  }
}

.container .dashboard-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.container .dashboard-container .newTable-container {
  width: 100%;
  max-width: 900px;
}

@media screen and (max-width: 930px) {
  .container .dashboard-container .newTable-container {
    max-width: 450px;
  }
}
