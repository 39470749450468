.chartContainer {
    background-color: white;
    color: #3a3f56 !important;
    padding: 10px;
    border-radius: 10px;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;

    display: block;

    

    h3 {
        text-align: center;
        font-weight: 600;
    }
}

.dashboard-chart, .video-chart {
    width: 100%;
    max-width: 900px;
}