.login-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-width: 100%;
  min-height: 100vh;
}

.login-container form {
  font-size: 16px;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 500px;
}

.login-container form > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  gap: 5px;
  margin-bottom: 15px;
}

.login-container form > div > input {
  width: 100%;
  padding: 10px;
  border: 1px solid lightblue;
  outline: none;
  border-radius: 5px;
}

.login-container form .error {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.login-container form .error p {
  color: red;
}

.login-container form .login-form-button-div {
  margin-top: 25px;
  margin-bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

.login-container form .login-form-button-div button {
  border: none;
  outline: none;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #1f6cc5da;
  color: white;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
}

.login-container form .login-form-button-div button:hover {
  background-color: #236fc5;
}
