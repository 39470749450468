.login-container {
    display: flex;
    justify-content: center;
    align-items: center;

    min-width: 100%;
    min-height: 100vh;

    form {
        font-size: 16px;
        background-color: white;
        padding: 20px;
        border-radius: 10px;

        width: 90%;
        max-width: 500px;

        & > div {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 5px;

            margin-bottom: 15px;

            & > input {
                width: 100%;
                padding: 10px;
                border: 1px solid lightblue;
                outline: none;
                border-radius: 5px;
            }
        }

        .error {
            display: flex;
            justify-content: center;
            align-items: center;
            p {
                color: red;
            }
        }

        .login-form-button-div {
            margin-top: 25px;
            margin-bottom: 0;

            display: flex;
            justify-content: flex-end;
            align-items: flex-end;

            button {
                border: none;
                outline: none;
                padding: 10px 20px;
                border-radius: 5px;

                background-color: #1f6cc5da;
                color: white;
                font-weight: 600;
                font-size: 16px;

                cursor: pointer;

                &:hover {
                    background-color: #236fc5;
                }
            }
            
        }
    }
}