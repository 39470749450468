.container {
    width: 100%;
    max-width: 1600px;
    margin: auto;
    margin-top: 20px;

    padding: 10px;

    @media screen and (max-width: 475px) {
        margin-top: 10px;
        padding: 5px;
    }


    h1, h2 {
        text-align: center;
    }

    h1 {
        font-size: 42px;
        font-weight: 600;
        padding: 0;
        margin: 0;

        @media screen and (max-width: 450px) {
            font-size: 36px;
        }
    }

    h2 {
        font-size: 22px;
        font-weight: 400;
        padding: 0;
        margin: 0;
        margin-bottom: 20px;

        @media screen and (max-width: 450px) {
            font-size: 18px;
        }
    }

    .all-container {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        @media screen and (max-width: 1150px) {
            flex-direction: column;
        }

        .TABLE-container {
            width: 60%;
            // background-color: white;
            @media screen and (max-width: 1150px) {
                width: 100%;
            }

            @media screen and (min-width: 1150px) and (max-width: 1530px) {
                width: 40%;
            }

            .newTable-container {
                width: 100%;
                max-width: 900px;

                @media screen and (min-width: 1150px) and (max-width: 1530px) {
                    max-width: 450px;
                }

                @media screen and (max-width: 1150px) {
                    margin: auto;
                }

                @media screen and (max-width: 930px) {
                    max-width: 450px;
                }
            }
        }

        .CHARTS-container {
            width: 29.8%;

            @media screen and (min-width: 1150px) and (max-width: 1530px) {
                width: 60%;
            }

            @media screen and (max-width: 1150px) {
                width: 100%;
            }
        }
    }

    .video-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        
        .newTable-container {
            width: 100%;
            max-width: 900px;

            @media screen and (max-width: 930px) {
                max-width: 450px;
            }
        }
    }

    .dashboard-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .newTable-container {
            width: 100%;
            max-width: 900px;

            @media screen and (max-width: 930px) {
                max-width: 450px;
            }
        }
    }
}