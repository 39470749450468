.newTable-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  gap: 20px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  color: white;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
}

@media screen and (max-width: 475px) {
  .newTable-container {
    padding: 10px;
    gap: 10px;
  }
}

.newTable-container .ONLINE {
  background-color: #60BEBB;
}

.newTable-container .OFFLINE {
  background-color: #DB4444;
}

.newTable-container .newTable-item-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  gap: 5px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  min-width: 360px;
  width: 420px;
  border-radius: 10px;
  padding: 10px 20px;
  height: 290px;
}

@media screen and (max-width: 450px) {
  .newTable-container .newTable-item-container {
    min-width: 320px;
    height: 230px;
  }
}

.newTable-container .newTable-item-container > div {
  margin: 10px 0;
}

@media screen and (max-width: 450px) {
  .newTable-container .newTable-item-container > div {
    margin: 5px 0;
  }
}

.newTable-container .newTable-item-container .newTable-domain {
  font-size: 28px;
}

@media screen and (max-width: 450px) {
  .newTable-container .newTable-item-container .newTable-domain {
    font-size: 22px;
  }
}

.newTable-container .newTable-item-container .newTable-flex-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
}

.newTable-container .newTable-item-container .newTable-users-cpu, .newTable-container .newTable-item-container .newTable-memory-ts {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.newTable-container .newTable-item-container .newTable-users-cpu > div, .newTable-container .newTable-item-container .newTable-memory-ts > div {
  width: 50%;
}

.newTable-container .newTable-item-container .newTable-users-cpu .newTable-text, .newTable-container .newTable-item-container .newTable-memory-ts .newTable-text {
  font-size: 12px;
  font-weight: normal;
}

.newTable-container .newTable-item-container .newTable-users-cpu .newTable-stats, .newTable-container .newTable-item-container .newTable-memory-ts .newTable-stats {
  font-weight: 600;
  font-size: 22px;
}

@media screen and (max-width: 450px) {
  .newTable-container .newTable-item-container .newTable-users-cpu .newTable-stats, .newTable-container .newTable-item-container .newTable-memory-ts .newTable-stats {
    font-size: 18px;
  }
}

.newTable-container .newTable-item-container .newTable-status p {
  font-size: 24px;
  font-weight: 500;
  margin: 0;
}

@media screen and (max-width: 450px) {
  .newTable-container .newTable-item-container .newTable-status p {
    font-size: 20px;
  }
}

.newTable-container .newTable-item-container .ONLINE-status {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 15px;
}

.newTable-container .newTable-item-container .ONLINE-status > div {
  display: inline;
  width: 24px;
  height: 24px;
  background-color: #64FF83;
  border-radius: 12px;
}

.newTable-container .newTable-item-container .OFFLINE-status {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 15px;
}

.newTable-container .newTable-item-container .OFFLINE-status > div {
  display: inline;
  width: 24px;
  height: 24px;
  background-color: #FF7A7A;
  border-radius: 12px;
}

.placeholderText-div {
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 22px;
}

@media screen and (max-width: 450px) {
  .placeholderText-div {
    height: 45.5px;
  }
}
