.table-container {
    background-color: white;
    color: #3a3f56 !important;
    padding: 20px;
    border-radius: 10px;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;

    display: block;

    width: auto;
    max-width: 90%;
    @media screen and (max-width: 768px) {
        max-width: 95%;
    }
    overflow-x: auto;


    h1 {
        text-align: center;
        padding-bottom: 20px;
    }

    .table {
        width: 100%;
        margin-bottom: 10px;
        font-size: 1.2rem;

        @media screen and (max-width: 768px) {
            font-size: 1rem;
        }

        margin-right: auto;
        margin-left: auto;

        th {
            border-bottom: 1px solid #e6edf5!important;
            text-align: left;
            white-space: nowrap;
            padding-right: 20px;
        }

        td {
            border-bottom: 1px solid #e6edf5!important;
            text-align: left;
            padding: 10px 0;
            padding-right: 20px;

            // height: 100px;
        }

        .online {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;

            & > div {
                display: inline;
                width: 16px;
                height: 16px;
                background-color: rgb(14, 237, 14);
                border-radius: 8px;
            }
        }

        .offline {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;

            & > div {
                display: inline;
                width: 16px;
                height: 16px;
                background-color: rgb(255, 55, 0);
                border-radius: 8px;
            }
        }
    }
}